<template>
  <div>
  <ContentHeader title="Penjualan Obat" url="/penjualan-obat" subTitle="Faktur Penjualan" />
     <div class="content-header">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body pl-4" style="display: block;">
           <h4 class="text-center mb-3">FAKTUR PENJUALAN</h4>
           <div class="row">
             <div class="col">
               <h4>{{penjual.nama}}</h4>
               <p>Surat Izin Operasi : {{penjual.sio}}</p>
               <p>{{penjual.alamat}}</p>
               <p>Telp. {{penjual.telepon}}</p>
             </div>
           </div>
           <hr style="height:10px;border:none;color:#808080;background-color:#808080;">
           <div class="row">
             <div class="col">
               <p>Nama Klinik : {{pembeli.nama}}</p>
               <p>Alamat      : {{pembeli.alamat}}</p>
               <p>Tanggal     : {{data.created_at}}</p>
               <p>No. Faktur  : {{data.nomor_faktur}}</p>
             </div>
           </div>

           <table class="table table-bordered mt-4">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Kode Barang</th>
                  <th scope="col">Nama Barang</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Satuan</th>
                  <th scope="col">Harga Satuan</th>
                  <th scope="col">Harga Jual</th>
                  <th scope="col">Diskon</th>
                  <th scope="col">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data.pembelian_detail" :key="index">
                  <td class="text-sm" scope="row">{{ index + 1 }}</td>
                  <td class="text-sm">{{ row.produk.kode }}</td>
                  <td class="text-sm">{{ row.produk.nama }}</td>
                  <td class="text-sm">{{ row.jumlah }}</td>
                  <td class="text-sm">{{ row.produk.satuan }}</td>
                  <td class="text-sm">{{ format_nominal(row.harga_beli) }}</td>
                  <td class="text-sm">{{ format_nominal(row.harga_jual) }}</td>
                  <td class="text-sm">{{ row.diskon }}</td>
                  <td class="text-sm">{{ format_nominal(row.total) }}</td>
                </tr>
                <tr>
                  <td colspan="3" class="text-center font-weight-bold">Total QTY</td>
                  <td class="font-weight-bold">{{ data.total_qty }}</td>
                  <td colspan="3" class="text-center font-weight-bold">Total</td>
                  <td class="font-weight-bold">{{ format_nominal(data.sub_total) }}</td>
                </tr>
                <tr>
                  <td colspan="4" style="border: none;"></td>
                  <td colspan="3" class="text-center font-weight-bold">Diskon</td>
                  <td class="font-weight-bold">{{ format_nominal(data.diskon) }}</td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td colspan="3" class="text-center font-weight-bold">Pajak</td>
                  <td class="font-weight-bold">{{ format_nominal(data.ppn) }}</td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td colspan="3" class="text-center font-weight-bold">Grand Total</td>
                  <td class="font-weight-bold">{{ format_nominal(data.total) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end mt-5">
              <div class="border-bottom border-dark mr-5 " style="height: 100px;width: 100px;">
                <p class="text-center">Hormat Kami</p>
              </div>
            </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer" style="display: block;">
          
        </div>
        <!-- /.card-footer-->
      </div>
    </div>
  </div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'

import { ref, onMounted, reactive } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import format_nominal from '@/format_nominal'

export default{
  components: {
    ContentHeader
  },
  setup(){
    const route = useRoute()
    const pemesanan_id = route.params.id
    const data = ref([])
    const pembeli = reactive({
      'nama' : '',
      'alamat' : '',
      'telepon' : ''
    })
    const penjual = reactive({
      'nama' : '',
      'sio' : '',
      'alamat' : '',
      'telepon' : ''
    }) 
    const getData = async () => {
      let response = await axios.get('api/pembelian/getPemesananDetail/' + pemesanan_id)
      
      if (response != 'kosong') {
        data.value = response.data

        pembeli.nama = response.data.pembeli.nama
        pembeli.alamat = response.data.pembeli.alamat
        pembeli.telepon = response.data.pembeli.telepon
        
        penjual.nama = response.data.penjual.nama
        penjual.sio = response.data.penjual.sio
        penjual.alamat = response.data.penjual.alamat
        penjual.telepon = response.data.penjual.telepon
      } 
    }

    onMounted(() => {
      getData()
    })

    return {
      data, pembeli, penjual, format_nominal
    }
  }
}
</script>